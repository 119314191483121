/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { ErrorPage } from '@app/pages/ErrorPage';
import { Layout } from '@metronic/layout';
import jwt_decode from 'jwt-decode';
import React, { useCallback, useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Switch, Route, useHistory } from 'react-router-dom';
import BasePage from './BasePage';
import { Logout } from './modules/Auth';
import AuthModule from './modules/Auth/refactor/AuthModule';

function ValidateUserTokenExp() {
  let isExpired = false;
  let authToken = false;

  const token = localStorage.getItem('persist:v706-demo1-auth');

  if (token) {
    authToken = JSON.parse(token).authToken;
  } else {
    isExpired = true;
    return isExpired;
  }

  if (authToken) {
    var decodedToken = jwt_decode(authToken);
    var dateNow = new Date();

    if (decodedToken.exp * 1000 < dateNow.getTime()) isExpired = true;
    if (isExpired) {
      localStorage.removeItem('persist:v706-demo1-auth');
    }
  }
  return isExpired;
}

export function Routes() {
  var isExpired = ValidateUserTokenExp();
  const history = useHistory();
  const lastVisitedPage = useRef('/streaming');
  const shouldRedirectToAnalytics = useRef(false);
  const { location } = history;

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user !== undefined && auth.permissions !== undefined
    }),
    shallowEqual
  );
  useEffect(() => {
    if (!isAuthorized) {
      const authPath = location.pathname.includes('auth') || location.pathname.includes('logout');
      const hasAnalyticsRedirection = location.search.includes('redirectTo');
      if (authPath && hasAnalyticsRedirection) {
        shouldRedirectToAnalytics.current = true;
        lastVisitedPage.current = location.search.replace('?redirectTo=/', '');
        return;
      }
      if (!authPath) {
        lastVisitedPage.current = `${location.pathname}${location.search}`;
      }
    }
  }, [isAuthorized, location]);
  const resetLastVisitedPage = useCallback(() => {
    lastVisitedPage.current = '/streaming';
  }, []);
  return (
    <Switch>
      {!isAuthorized || isExpired ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthModule />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized || isExpired ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage
            shouldRedirectToAnalytics={shouldRedirectToAnalytics.current}
            lastVisitedPage={lastVisitedPage.current}
            onResetLastVisitedPage={resetLastVisitedPage}
          />
        </Layout>
      )}
    </Switch>
  );
}
