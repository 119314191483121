import React from 'react';

const AsideMenuSection = ({ title, className }) => {
  return (
    <li className={`menu-section ${className}`}>
      <h4 className="menu-text">{title}</h4>
      <i className="menu-icon flaticon-more-v2" />
    </li>
  );
};

export default AsideMenuSection;
