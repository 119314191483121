import { Button } from '@material-ui/core';
import { LockOpen as LockOpenIcon } from '@material-ui/icons';
import React from 'react';

const CancelIntegrationButton = ({ disabled, onClick }) => (
  <Button
    disabled={disabled}
    color="error"
    size="large"
    startIcon={<LockOpenIcon sx={{ color: 'common.white' }} />}
    sx={{ height: 37, width: '100%', '&:disabled': { color: 'common.white', bgcolor: 'muted' } }}
    onClick={onClick}
  >
    Desfazer integração
  </Button>
);

export default CancelIntegrationButton;
