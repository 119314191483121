import axios from 'axios';
import FormData from 'form-data';
export const createVOD = payload => axios.post(`${process.env.REACT_APP_NOVA_API_URL}/v1/videos/vod`, payload);
export const deleteVOD = videoId => axios.delete(`${process.env.REACT_APP_NOVA_API_URL}/v1/videos/${videoId}`);
export const getVOD = videoId => axios.get(`${process.env.REACT_APP_NOVA_API_URL}/v1/videos/${videoId}`).then(response => response.data);
export const findVODs = title =>
  axios.get(`${process.env.REACT_APP_NOVA_API_URL}/v1/videos/vod?limit=500&title=${title}`).then(response => response.data);
export const findVODsByTag = title =>
  axios.get(`${process.env.REACT_APP_NOVA_API_URL}/v1/videos/vod?limit=500&tags=${title}`).then(response => response.data);
export const listVODs = payload =>
  axios.get(`${process.env.REACT_APP_NOVA_API_URL}/v1/videos/vod?page=1&limit=500`, payload).then(response => response.data);
export const updateVOD = (videoId, payload) => axios.patch(`${process.env.REACT_APP_NOVA_API_URL}/v1/videos/${videoId}`, payload);
export const uploadVODThumbnail = async (key, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(`${process.env.REACT_APP_NOVA_API_URL}/v1/videos/${key}/thumbnail`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};
export const uploadVODEndScreen = async (key, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(`${process.env.REACT_APP_NOVA_API_URL}/v1/videos/${key}/end-screen`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};
export const getSettings = directoryId => axios.get(`${process.env.REACT_APP_NOVA_API_URL}/v1/directories/${directoryId}/settings`);
export const generateAutomaticSubtitle = videoId => axios.post(`${process.env.REACT_APP_NOVA_API_URL}/v1/videos/${videoId}/subtitles/job`);
export const getGenerationSubtitleStatus = videoId => axios.get(`${process.env.REACT_APP_NOVA_API_URL}/v1/videos/${videoId}/subtitles/job`);
export const createManualSubtitle = (subtitle, videoId) => {
  const formPayload = new FormData();
  formPayload.append('file', subtitle);
  return axios.post(`${process.env.REACT_APP_NOVA_API_URL}/v1/videos/${videoId}/subtitles/upload`, formPayload);
};
export const removeSubtitle = videoId => axios.delete(`${process.env.REACT_APP_NOVA_API_URL}/v1/videos/${videoId}/subtitle`);
