import Axios from 'axios';

export const zoomActivate = payload => Axios.put(`${process.env.REACT_APP_NOVA_API_URL}/bff/zoom/importacao-ativa`, payload);
export const tencentActivate = payload => Axios.put(`${process.env.REACT_APP_NOVA_API_URL}/bff/tencent/vod/importacao-ativa`, payload);
export const integrateTencent = payload => Axios.post(`${process.env.REACT_APP_NOVA_API_URL}/bff/tencent/vod/migra-gravacoes`, payload);
export const cancelTencentIntegration = () => Axios.delete(`${process.env.REACT_APP_NOVA_API_URL}/bff/tencent/vod/migra-gravacoes`);
export const getTencentStatus = () => Axios.get(`${process.env.REACT_APP_NOVA_API_URL}/bff/tencent/vod/importacao-ativa`).then(data => data.data);
export const getStatusToken = () => Axios.get(`${process.env.REACT_APP_NOVA_API_URL}/bff/zoom/status-token`).then(data => data.data);
// Extrai a URL de autorização do ambiente
const getAuthorizationUrl = accountId => {
  const url = `${process.env.REACT_APP_NOVA_API_URL}/bff/zoom/oauth?account_id=${accountId}`;
  return url;
};
// Abre uma janela pop-up para autenticação
export const openAuthWindow = url => {
  const width = 600;
  const height = 800;
  const left = window.innerWidth / 2 - width / 2;
  const top = window.innerHeight / 2 - height / 2;
  const windowFeatures = `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,toolbar=no`;
  return window.open(url, '_blank', windowFeatures);
};
// Verifica periodicamente a existência do token de autenticação
const checkForAuthToken = async authWindow => {
  const response = await new Promise(resolve => {
    const interval = setInterval(async () => {
      try {
        const response = await getStatusToken();
        const isLoggedIn = !response.deauthorized;
        if (isLoggedIn) {
          // Se retornar true fecha o popup
          authWindow.close();
          clearInterval(interval);
          resolve(true);
        }
        if (authWindow.closed) {
          resolve(false);
          clearInterval(interval);
        }
      } catch {}
    }, 2000);
  });
  return response;
};
// Função principal para obter um token de autenticação
export const getZoomAuthToken = async accountId => {
  const authUrl = getAuthorizationUrl(accountId); // Obter a URL de autorização
  const authWindow = openAuthWindow(authUrl); // Abrir janela de autenticação

  // Verificar periodicamente se está logado
  return checkForAuthToken(authWindow);
};
