import StepIcon from '@app/components/StepIcon';
import { colors, createTheme, ThemeProvider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import hexToRgba from '../../../app/utils/hexToRgba';

export const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 } },
  typography: { fontFamily: 'Poppins' },
  palette: {
    primary: { main: '#3699FF', dark: '#000', light: '#E1F0FF' },
    secondary: { main: '#181C32', dark: '#000', light: '#F3F6F9' },
    success: { main: '#1BC5BD', dark: '#000', light: '#C9F7F5' },
    error: { main: '#F64E60', dark: '#000', light: '#FFE2E5' },
    warning: { main: '#FFA800', dark: '#000', light: '#FFF4DE' },
    info: { main: '#8950FC', dark: '#000', light: '#EEE5FF' },
    white: { main: '#FFFFFF', dark: '#000', light: '#EEE5FF' },
    neutral: {
      main: '#1F1F1F',
      body: '#3F4254',
      light: '#F3F6F9',
      75: '#575757',
      50: '#7E8299',
      25: '#D1D3E0'
    },
    muted: '#B5B5C3'
  },
  spacing: [0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 96],
  shape: { borderRadius: 4, size: { small: 29, medium: 36, large: 40 } }
});
theme.components = {
  MuiButtonBase: { defaultProps: { disableRipple: false } },
  MuiButton: {
    defaultProps: {
      color: 'primary',
      disableElevation: true,
      variant: 'contained'
    },
    styleOverrides: {
      root: {
        borderRadius: theme.shape.borderRadius,
        fontSize: '0.923rem',
        fontWeight: 600,
        height: theme.shape.size.medium,
        textTransform: 'none'
      },
      contained: {
        backgroundColor: theme.palette.neutral.light,
        color: theme.palette.neutral[50],
        '&:hover': { backgroundColor: theme.palette.neutral[25] }
      },
      containedPrimary: {
        backgroundColor: theme.palette.primary.main,
        color: colors.common.white,
        '&:hover': { backgroundColor: theme.palette.primary.main }
      },
      sizeSmall: { fontSize: '0.846rem', height: theme.shape.size.small },
      sizeLarge: { fontSize: '1rem', height: theme.shape.size.large }
    },
    variants: [
      {
        props: { color: 'primary', variant: 'outlined' },
        style: {
          backgroundColor: theme.palette.primary.light,
          border: 'none',
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: '#BCE2FF', // export from theme
            border: 'none'
          }
        }
      },
      {
        props: { color: 'neutral', variant: 'outlined' },
        style: {
          backgroundColor: theme.palette.neutral.light,
          border: 'none',
          color: theme.palette.neutral[50],
          '&:hover': {
            backgroundColor: 'neutral.25',
            border: 'none'
          }
        }
      },
      {
        props: { color: 'white', variant: 'contained' },
        style: {
          backgroundColor: theme.palette.white.main,
          border: 'none',
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.white.main,
            border: 'none'
          }
        }
      },
      {
        props: { color: 'error' },
        style: {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.light,
          '&:hover': { backgroundColor: '#EC2A43' } // export from theme
        }
      },
      {
        props: { color: 'error', variant: 'outlined' },
        style: {
          backgroundColor: theme.palette.error.light,
          border: 'none',
          color: theme.palette.error.main,
          '&:hover': { backgroundColor: theme.palette.error.light, border: 'none' }
        }
      },
      {
        props: { color: 'error', variant: 'text' },
        style: {
          backgroundColor: 'white',
          color: theme.palette.error.main,
          '&:hover': { backgroundColor: 'white' }
        }
      },
      {
        props: { variant: 'text' },
        style: {
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: hexToRgba(theme.palette.neutral.body, 0.1)
          }
        }
      },
      {
        props: { variant: 'text', color: 'neutral' },
        style: {
          backgroundColor: 'white',
          color: theme.palette.neutral.body
        }
      },
      {
        props: { color: 'info' },
        style: {
          backgroundColor: theme.palette.info.main,
          color: theme.palette.common.white,
          '&:hover': { backgroundColor: theme.palette.info.main }
        }
      },
      {
        props: { color: 'warning' },
        style: {
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.warning.light,
          '&:hover': { backgroundColor: theme.palette.warning.main }
        }
      },
      {
        props: { color: 'warning', variant: 'outlined' },
        style: {
          backgroundColor: theme.palette.warning.light,
          color: theme.palette.warning.main,
          '&:hover': { backgroundColor: theme.palette.warning.light }
        }
      }
    ]
  },
  MuiCard: {
    styleOverrides: { root: { borderRadius: theme.shape.borderRadius * 2 } }
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: theme.spacing(4),
        '&:last-child': { paddingBottom: null }
      }
    }
  },
  MuiChip: {
    defaultProps: { color: 'primary', deleteIcon: <CloseIcon /> },
    styleOverrides: {
      root: {
        borderRadius: theme.shape.borderRadius,
        height: theme.shape.size.small,
        '& .MuiChip-deleteIcon': {
          color: theme.palette.neutral[50],
          fontSize: 12 // TODO: export from theme
        }
      },
      label: {
        fontSize: 13 // TODO: export from theme
      }
    },
    variants: [
      {
        props: { color: 'primary' },
        style: {
          backgroundColor: theme.palette.neutral.light,
          color: theme.palette.neutral.body
        }
      },
      {
        props: { color: 'success' },
        style: {
          backgroundColor: theme.palette.success.light,
          color: theme.palette.neutral.body
        }
      },
      {
        props: { color: 'secondary' },
        style: {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main
        }
      },
      {
        props: { color: 'error' },
        style: {
          backgroundColor: theme.palette.error.light,
          color: theme.palette.error.main
        }
      },
      {
        props: { color: 'warning' },
        style: {
          backgroundColor: theme.palette.warning.light,
          color: theme.palette.warning.main
        }
      },
      {
        props: { color: 'info' },
        style: {
          backgroundColor: theme.palette.info.light,
          color: theme.palette.info.main
        }
      }
    ]
  },
  MuiFormHelperText: {
    defaultProps: { color: 'primary' },
    variants: [
      {
        props: { color: 'error' },
        style: {
          fontSize: 12,
          color: theme.palette.error.main
        }
      }
    ]
  },
  MuiIcon: {
    styleOverrides: {
      fontSizeSmall: {
        fontSize: 16 // TODO: export from theme
      },
      fontSizeLarge: {
        fontSize: 30 // TODO: export from theme
      }
    }
  },
  MuiIconButton: {
    defaultProps: { color: 'primary' },
    styleOverrides: {
      root: { padding: theme.spacing(2) },
      colorPrimary: { color: theme.palette.muted }
    }
  },
  MuiInputBase: {
    styleOverrides: { root: { color: theme.palette.neutral[50] } }
  },
  MuiLinearProgress: {
    defaultProps: { variant: 'determinate' },
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.neutral.light,
        borderRadius: 100,
        height: 5,
        overflow: 'hidden'
      }
    },
    variants: [
      {
        props: { color: 'error' },
        style: { backgroundColor: theme.palette.error.light }
      }
    ]
  },
  MuiPagination: {
    defaultProps: {
      siblingCount: 6,
      shape: 'rounded',
      showFirstButton: true,
      showLastButton: true
    },
    styleOverrides: { ul: { justifyContent: 'center' } }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        border: `1px solid ${theme.palette.neutral[25]}`,
        borderRadius: theme.shape.borderRadius,
        fieldset: { display: 'none' },
        legend: { display: 'none' },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.neutral[25]}`
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.neutral[25]}`
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.neutral[25]}`
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.error.main} !important`
        },
        'input:-webkit-autofill': {
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: `${theme.palette.neutral[50]} !important`
        }
      }
    }
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        borderRadius: theme.shape.borderRadius * 2,
        color: theme.palette.neutral[50],
        fontSize: 12, // TODO: export from theme
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main
        },
        '&.Mui-selected': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white
        },
        '&.Mui-disabled .MuiSvgIcon-root': { color: theme.palette.muted }
      },
      firstLast: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.neutral[50],
        fontWeight: 'bold'
      },
      previousNext: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.neutral[50],
        fontWeight: 'bold'
      },
      icon: { color: theme.palette.neutral[50] }
    }
  },
  MuiPaper: { defaultProps: { elevation: 0 } },
  MuiPopover: { defaultProps: { elevation: 1 } },
  MuiSvgIcon: {
    styleOverrides: { colorPrimary: { color: theme.palette.primary.main } }
  },
  MuiStepLabel: {
    defaultProps: { StepIconComponent: StepIcon },
    styleOverrides: {
      root: {
        '& .MuiStepLabel-label': {
          color: theme.palette.neutral[50],
          fontSize: 14, // TODO: export from theme
          fontWeight: 600,
          '&.Mui-completed': {
            color: theme.palette.primary.main
          },
          '&.Mui-active': {
            color: theme.palette.primary.main,
            fontWeight: 400
          }
        }
      }
    }
  },
  MuiSwitch: {
    defaultProps: { disableRipple: true },
    styleOverrides: {
      root: {
        height: 24,
        padding: 0,
        width: 42,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          transform: 'translate(5px, 5px)'
        },
        '& .MuiSwitch-switchBase:hover': { backgroundColor: 'transparent' },
        '& .MuiSwitch-switchBase.Mui-checked': {
          transform: 'translate(22px, 5px)'
        },
        '& .MuiSwitch-switchBase.Mui-checked:hover': {
          backgroundColor: 'transparent !important'
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: theme.palette.common.white,
          height: 15,
          width: 15
        },
        '& .MuiSwitch-track': {
          backgroundColor: '#E2E3EB', // TODO: export from theme
          borderRadius: 100,
          opacity: 1
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: `${theme.palette.primary.main} !important`,
          opacity: 1
        }
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${theme.palette.info.light}`,
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        position: 'relative',
        '&:first-of-type': { paddingLeft: 0, '&::before': { display: 'none' } },
        '&::before': {
          backgroundColor: theme.palette.info.light,
          content: '" "',
          height: 'calc(100% - 32px)',
          left: 0,
          position: 'absolute',
          top: 16,
          width: 1
        }
      }
    }
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&:first-of-type': {
          borderTop: `1px solid ${theme.palette.info.light}`
        }
      }
    }
  },
  MuiTooltip: {
    defaultProps: { arrow: true },
    styleOverrides: {
      tooltip: {
        fontSize: 12, // TODO: export from theme
        fontWeight: 'normal',
        padding: theme.spacing(2),
        textAlign: 'center'
      }
    }
  },
  MuiTextField: {
    defaultProps: { fullWidth: true, minRows: 2, size: 'small' },
    styleOverrides: {
      root: {
        '& .MuiFormHelperText-root': { marginLeft: theme.spacing(3) },
        '& input::placeholder, & input::-webkit-input-placeholder, & textarea::placeholder, & textarea::-webkit-input-placeholder': {
          color: theme.palette.muted,
          opacity: 1
        }
      }
    }
  },
  MuiTypography: {
    defaultProps: { color: theme.palette.neutral.body },
    styleOverrides: { h3: { fontSize: '1.53846153846rem', fontWeight: 600 } }
  }
};
export function MaterialThemeProvider(props) {
  const { children } = props;
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
