import { Box, Button, Divider, Modal, Stack, Typography } from '@material-ui/core';
import { CloseRounded as CloseRoundedIcon } from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

const AuthWithZoom = forwardRef(({ buttonConfirmTitle, color = 'primary', description, loading, title, onConfirm }, ref) => {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);
  useImperativeHandle(
    ref,
    () => ({
      openModal,
      closeModal
    }),
    []
  );
  return (
    <Modal open={open} onClose={closeModal}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          left: '50%',
          p: 4,
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ color: 'neutral.dark', fontSize: 16 }}>{title}</Typography>
          <CloseRoundedIcon sx={{ color: 'muted', cursor: 'pointer' }} onClick={closeModal} />
        </Stack>
        <Divider sx={{ bgcolor: 'grey.200', my: 3 }} />
        <Typography sx={{ color: 'neutral.body', fontSize: 14 }}>{description}</Typography>
        <Divider sx={{ bgcolor: 'grey.200', my: 3 }} />
        <Stack gap={2}>
          <LoadingButton color={color} loading={loading} onClick={onConfirm}>
            {buttonConfirmTitle}
          </LoadingButton>
          <Button sx={{ bgcolor: 'neutral.light', color: 'neutral.50', '&:hover': { bgcolor: 'neutral.light' } }} onClick={closeModal}>
            Cancelar
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
});

export default AuthWithZoom;
